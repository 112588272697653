<template>
  <div>
    <b-modal
      id="modal-return"
      centered
      hide-footer
      size="lg"
      title="Pengembalian Inventaris"
      class="pb-3"
    >
      <div
        v-if="detail.id !== undefined"
      >
        <b-row class="my-1">
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Tanggal Peminjaman :"
            >
              <b>{{ detail.loan_start }} s/d {{ detail.loan_end }}</b>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            cols="12"
          >
            <b-form-group
              label="Tanggal Pengajuan :"
            >
              <b>{{ detail.booking_date }} (oleh {{ detail.requester.name }})</b>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col
            cols="12"
            md="12"
          >
            <b-form
              ref="form"
              :style="{height: 200}"
              class="repeater-form"
              @submit.prevent="repeateAgain"
            >
              <b-row
                v-for="(item) in detail.inventories"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <!-- Item Name -->
                <b-col md="3">
                  <b-form-group
                    label="Inventaris"
                  >
                    <b>{{ item.name }}</b><br>
                    <small>{{ item.inventory_code }}</small>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    label="Kondisi"
                  >
                    <b-form-select
                      v-model="item.condition"
                      :options="conditionList"
                      class="d-inline-block mr-1"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Catatan"
                  >
                    <b-form-input
                      v-model="item.note"
                      placeholder="Enter Note"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    label="Denda"
                  >
                    <b-form-input
                      v-model="item.debt"
                      placeholder="Enter Debt"
                      :disabled="!$can('update', 'Loan')"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  v-if="$can('create', 'Loan')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="handleSave()"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                  />
                  <span>Simpan</span>
                </b-button>
                <b-button
                  v-if="$can('update', 'Loan') && detail.status == 'REQUEST RETURN'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="handleSave()"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                  />
                  <span>Verifikasi</span>
                </b-button>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal, BRow, BCol, BForm, BFormGroup, BFormInput, BFormSelect, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      conditionList: [
        { text: 'Pilih Kondisi', value: null },
        { text: 'Baik', value: 1 },
        { text: 'Rusak ringan', value: 2 },
        { text: 'Rusak sedang', value: 3 },
        { text: 'Rusak berat', value: 4 },
        { text: 'Tidak dapat diperbaiki', value: 5 },
        { text: 'Hilang', value: 6 },
      ],
      form: {
        inventories: [],
      },
    }
  },
  methods: {
    handleSave() {
      this.form.inventories = []
      this.detail.inventories.forEach(item => {
        this.form.inventories.push({
          id: item.id,
          condition: item.condition,
          note: item.note,
          debt: item.debt,
        })
      })

      this.$http.put(`/v1/loan/inventories/${this.detail.id}`, this.form)
        .then(() => {
          this.$bvModal.hide('modal-return')
          this.$emit('success')
          this.$emit('refresh')
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.$emit('error', error.response.data.messages)
        })
    },
  },
}
</script>
