<template>
  <div>
    <b-modal
      id="modal-detail"
      centered
      hide-footer
      size="lg"
      :title="modalTitle"
      class="pb-3"
    >
      <div
        v-if="detail.id !== undefined"
      >
        <b-card-text class="mt-1 mb-75">
          <b-alert
            v-if="errors.length > 0"
            variant="danger"
            show
          >
            <div class="alert-body">
              <p
                v-for="error in errors"
                :key="error"
              >
                {{ error }}
              </p>
            </div>
          </b-alert>
          <b-row>
            <b-col
              md="6"
              cols="12"
            >
              <b-form-group
                label="Tanggal Peminjaman :"
              >
                <b>{{ detail.loan_start }} s/d {{ detail.loan_end }}</b>
              </b-form-group>
              <b-form-group
                label="Tanggal Pengajuan :"
              >
                <b>{{ detail.booking_date }} (oleh {{ detail.requester.name }})</b>
              </b-form-group>
              <b-form-group
                v-if="detail.return_date != ''"
                label="Tanggal Pengembalian :"
              >
                <b>{{ detail.return_date }} </b>
              </b-form-group>
              <b-form-group
                label="Status :"
              >
                <b-badge
                  variant="light-primary"
                  class="mb-75"
                >
                  {{ detail.status }} 
                </b-badge>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
              cols="12"
            >
              <b-form-group
                label="Anggota :"
              >
                <b-list-group>
                  <b-list-group-item
                    v-for="(member, index) in detail.members"
                    :key="member.id"
                  >
                    <b>{{ index+1 }}. {{ member.name }} <span v-if="member.nim">({{ member.nim }})</span></b>
                  </b-list-group-item>
                </b-list-group>
              </b-form-group>
              <b-form-group
                v-if="$can('create', 'Loan') && detail.status == 'REQUEST'"
                label="Upload Form Pengajuan :"
              >
                <b-form-file
                  v-model="file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
              </b-form-group>
              <div
                v-if="$can('create', 'Loan') && detail.status == 'REQUEST'"
                class="d-flex align-items-center justify-content-end mb-1"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="handleFormUpload()"
                >
                  <feather-icon
                    icon="UploadIcon"
                    class="mr-50"
                  />
                  Upload
                </b-button>
              </div>
            </b-col>
            <b-col
              md="12"
              cols="12"
            >
              <b-form-group
                v-if="detail.loan_reason"
                label="Tujuan Peminjaman :"
              >
                <b>{{ detail.loan_reason }}</b>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              cols="12"
            >
              <b-form-group
                v-if="detail.description"
                label="Deskripsi :"
              >
                <b>{{ detail.description }}</b>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              md="12"
              cols="12"
            >
              <div class="row mb-1">
                <div class="col-10">
                  <span class="label">List Inventaris : </span>
                </div>
                <div class="col-2">
                  <b-button
                    v-if="$can('update', 'Loan') && detail.status == 'REQUEST' && ((userData.role == 'admin tool' && detail.inventories[0].type == 'tool') || (userData.role == 'admin infra' && detail.inventories[0].type == 'facility'))"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="btn-sm float-right"
                    @click="$router.push(`/loan/update/${detail.id}`)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-button>
                </div>
              </div>
              <b-form-group>
                <b-table
                  class="position-relative"
                  :items="detail.inventories"
                  small="true"
                  :fields="inventoryColumns"
                  primary-key="id"
                  show-empty
                  empty-text="No matching records found"
                >
                  <template #cell(no)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(action)="data">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-if="detail.status == 'REQUEST' && ((userData.role == 'admin tool' && detail.inventories[0].type == 'tool') || (userData.role == 'admin infra' && detail.inventories[0].type == 'facility')) "
                      class="btn-sm"
                      variant="outline-danger"
                      @click="handleRemoveInventory(data.item.loan_inventory_id)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                    </b-button>
                  </template>
                </b-table>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-text>
        <div
          class="d-flex align-items-center justify-content-end mb-1"
        >
          <small
            v-if="detail.form_path == ''"
          >
            <i>*peminjam belum melakukan upload form pengajuan</i>
          </small>
        </div>
        <div
          class="d-flex align-items-center justify-content-end mb-1"
        >
          <b-link
            :href="detail.form_path"
          >
            <b-button
              v-if="detail.form_path"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mr-1"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>Form Pengajuan</span>
            </b-button>
          </b-link>
          <b-link
            :href="detail.location_permission_path"
          >
            <b-button
              v-if="detail.location_permission_path"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              class="mr-1"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>Izin Lokasi Syuting</span>
            </b-button>
          </b-link>
          <b-button
            v-if="$can('update', 'Loan') && detail.status == 'REQUEST' && ((userData.role == 'admin tool' && detail.inventories[0].type == 'tool') || (userData.role == 'admin infra' && detail.inventories[0].type == 'facility')) "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="mr-1"
            @click="handleVerification('rejected')"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span>Tolak</span>
          </b-button>
          <b-button
            v-if="$can('update', 'Loan') && detail.status == 'REQUEST' && ((userData.role == 'admin tool' && detail.inventories[0].type == 'tool') || (userData.role == 'admin infra' && detail.inventories[0].type == 'facility')) " 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="handleVerification('approved')"
          >
            <feather-icon
              icon="CheckIcon"
              class="mr-50"
            />
            <span>Setujui</span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, VBModal, BBadge, BCardText, BRow, BCol, BFormGroup, BButton, BListGroup, BListGroupItem, BFormFile, BLink, BAlert, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BBadge,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BLink,
    BAlert,
    BTable,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Detail Alat',
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
    verify: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [],
      file: null,
      inventoryColumns: [
        {
          key: 'no',
        },
        {
          key: 'name',
          label: 'Nama',
        },
        {
          key: 'inventory_code',
          label: 'Nomor Inventaris',
        },
        {
          key: 'condition_formatted',
          label: 'Kondisi',
        },
        {
          key: 'debt',
          label: 'Denda',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    handleVerification(status) {
      this.detail.status = status
      this.$http.put(`/v1/loan/update-status/${this.detail.id}`, this.detail)
        .then(() => {
          this.$bvModal.hide('modal-detail')
          this.$emit('success')
          this.$emit('refresh')
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.$emit('error', error.response.data.messages)
        })
    },
    handleFormDownload() {
      this.$http.get(`/v1/loan/${this.detail.id}/form`, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `form-peminjaman-${this.detail.id}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.$emit('error', error.response.data.messages)
        })
    },
    handleFormUpload() {
      const formData = new FormData()
      formData.append('file', this.file)

      this.$http.post(`/v1/loan/upload/${this.detail.id}`, formData)
        .then(() => {
          this.$emit('success')
          this.$emit('refresh-detail', this.detail.id)
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.$emit('error', error.response.data.messages)
        })
    },
    handleRemoveInventory(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete everything.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$http.delete(`/v1/loan/inventory/${id}`)
              .then(() => {
                this.$emit('success')
                this.$emit('refresh-detail', this.detail.id)
              })
              .catch(error => {
                this.errorToast(error.response.data.messages)
              })
          }
        })
    },
  },
}
</script>
