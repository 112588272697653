<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            List Peminjaman
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-select
                v-model="searchStatus"
                :options="statusList"
                class="d-inline-block mr-1"
              />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-link
                to="/loan/create"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  :disabled="!availableStatus"
                >
                  <span class="text-nowrap">Buat Pinjaman</span>
                </b-button>
              </b-link>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-overlay
        :show="overlay"
        rounded="sm"
      >
        <b-table
          class="position-relative"
          :items="fetchData"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(no)="data">
            {{ (fetchMeta.current_page - 1) * fetchMeta.per_page + data.index + 1 }}
          </template>
          <template #cell(name)="data">
            {{ data.item.requester.name }} <br>
            <small>{{ data.item.requester.nim }}</small>
          </template>
          <template #cell(loan_date)="data">
            {{ data.item.loan_start }} s/d {{ data.item.loan_end }}
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolverStatus(data.item.status)}`"
              class="text-capitalize"
            >
              {{ translateStatus(data.item.status,data.item.form_path) }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-b-modal.modal-detail
                @click="handleDetail(data.item.id)"
              >
                <feather-icon icon="EyeIcon" />
                <span class="align-middle ml-50">Detail</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$can('create', 'Loan') && data.item.status === 'REQUEST'"
                @click="handleDownloadForm(data.item.id)"
              >
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">Download Form</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$can('create', 'Loan') && data.item.status === 'APPROVED'"
                v-b-modal.modal-return
                @click="handleReturn(data.item.id)"
              >
                <feather-icon icon="CheckSquareIcon" />
                <span class="align-middle ml-50">Return</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$can('update', 'Loan') && data.item.status === 'REQUEST RETURN'"
                v-b-modal.modal-return
                @click="handleReturn(data.item.id)"
              >
                <feather-icon icon="CheckSquareIcon" />
                <span class="align-middle ml-50">Approve Return</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="data.item.status === 'REQUEST'"
                @click="handleDelete(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ fetchMeta.current_page }} from {{ fetchMeta.last_page }} Pages</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="fetchMeta.total"
                :per-page="page.size"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
    <ModalDetail
      :modal-title="modalTitle"
      :detail="formData"
      @refresh="getLoanList()"
      @refresh-detail="getLoanDetail($event)"
      @success="successToast()"
      @error="errorToast($event)"
    />
    <ModalReturn
      :detail="formData"
      @refresh="getLoanList()"
      @success="successToast()"
      @error="errorToast($event)"
    />
  </div>
</template>
<script>
import {
  BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BDropdown, BDropdownItem, BFormSelect, BBadge, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import ModalDetail from './ModalDetail.vue'
import ModalReturn from './ModalReturn.vue'

export default {
  components: {
    ModalDetail,
    ModalReturn,

    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BBadge,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      overlay: true,
      currentPage: 1,
      searchQuery: '',
      searchStatus: null,
      fetchData: [],
      fetchMeta: {},
      tableColumns: [
        { key: 'no' },
        { key: 'name', label: 'Pemohon' },
        { key: 'booking_date', label: 'Tanggal Pengajuan' },
        { key: 'loan_date', label: 'Tanggal Peminjam' },
        { key: 'max_return', label: 'Batas Pengembalian' },
        { key: 'status' },
        { key: 'actions' },
      ],
      page: {
        size: 10,
        number: 1,
      },
      filter: {
        search: '',
        category_id: null,
      },
      modalTitle: '',
      formData: {},
      statusList: [
        { text: 'Select Status', value: null },
        { text: 'Request', value: 'request' },
        { text: 'Approved', value: 'approved' },
        { text: 'Rejected', value: 'rejected' },
        { text: 'Request return', value: 'request return' },
        { text: 'Returned', value: 'returned' },
        { text: 'Cancelled', value: 'cancelled' },
      ],
      availableStatus: true,
    }
  },
  watch: {
    currentPage() {
      this.page.number = this.currentPage
      this.getLoanList()
    },
    searchQuery() {
      this.filter.search = this.searchQuery
      this.getLoanList()
    },
    searchStatus() {
      this.filter.status = this.searchStatus
      this.getLoanList()
    },
  },
  mounted() {
    this.getLoanList()
    this.getAvailableStatus()
  },
  methods: {
    getLoanList() {
      this.overlay = true
      const data = {
        params: {
          'page[number]': this.page.number,
          'page[size]': this.page.size,
          'filter[search]': this.filter.search,
          'filter[status]': this.filter.status,
        },
      }

      this.$http.get('/v1/loan/list', data)
        .then(response => {
          this.setFetchData(response.data.data)
          this.setFetchMeta(response.data.meta)
          this.overlay = false
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    getLoanDetail(id) {
      this.$http.get(`/v1/loan/${id}`)
        .then(response => {
          this.formData = response.data.data
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    setFetchData(data) {
      this.fetchData = data
    },
    setFetchMeta(data) {
      this.fetchMeta = data
    },
    handleDetail(id) {
      this.modalTitle = 'Detail Peminjaman'
      this.getLoanDetail(id)
    },
    handleDownloadForm(id) {
      this.$http.get(`/v1/loan/generate/${id}`, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'form-peminjaman.docx')
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    handleReturn(id) {
      this.getLoanDetail(id)
    },
    handleUpload(id) {
      this.getLoanDetail(id)
    },
    handleDelete(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete everything.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$http.delete(`/v1/loan/${id}`)
              .then(() => {
                this.successToast()
                this.getLoanList()
              })
              .catch(error => {
                this.errorToast(error.response.data.messages)
              })
          }
        })
    },
    resolverStatus(status) {
      if (status === 'APPROVED') {
        return 'success'
      }

      if (status === 'REQUEST') {
        return 'warning'
      }

      if (status === 'REJECTED') {
        return 'danger'
      }

      if (status === 'REQUEST RETURN') {
        return 'warning'
      }

      if (status === 'RETURNED') {
        return 'primary'
      }

      return 'primary'
    },
    translateStatus(status, file) {
      if (status === 'APPROVED') {
        return 'Disetujui'
      }

      if (status === 'REQUEST' && file === '') {
        return 'Menunggu Upload File'
      }

      if (status === 'REQUEST') {
        return 'Menunggu Persetujuan'
      }

      if (status === 'REJECTED') {
        return 'Ditolak'
      }

      if (status === 'REQUEST RETURN') {
        return 'Menunggu Persetujuan Pengembalian'
      }

      if (status === 'RETURNED') {
        return 'Dikembalikan'
      }

      return 'Dibatalkan'
    },
    successToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    errorToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
    getAvailableStatus() {
      this.$http.get('/v1/loan/available-status')
        .then(response => {
          this.availableStatus = response.data.data.is_available
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
  },
}
</script>
